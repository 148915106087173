<template>
  <div class="row feed boxw mb-1" >
    <div class="box pb-2">
      <div style="padding:10px;position:relative">
        <!--<h3>Pesquisa</h3>-->
        <div class="input-group col-12 search-bar">
          <i class="fa fa-search" aria-hidden="true"></i>
          <input type="search" v-model="search" :placeholder="$t('search.search_placeholder')" aria-describedby="button-addon5" class="form-control pl-3">
        </div>
      </div>
      <masonry-wall :items="items" :ssr-columns="3" :column-width="200" :gap="16">
        <template #default="{ item, index }">
          <div>
            <router-link :to="'/profile/' + item.id + '/' + item.username" :rel="index"><img style="max-width: 100%" v-lazy="'/users/' + Math.floor(item.id / 1000) + '/' + item.username + '/imgp.jpg'"></router-link>
          </div>
        </template>
      </masonry-wall>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { search } from './../resources/fn'
import { error } from '../resources/functions'
export default {
  name: 'search',
  data () {
    return {
      items: [],
      options: {
        width: 300,
        padding: {
          default: 12,
          1: 6,
          2: 8
        }
      },
      search: ''
    }
  },
  beforeMount () {
    // this.init()
  },
  computed: {
    ...mapState({
      user: 'user'
    })
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_USER'
    ]),
    init () {
      this.SET_LOAD(true)
      search(this.axios, { username: this.search }).then((res) => {
        this.items = res.data.data
        this.SET_LOAD()
      }, (res) => {
        error(this, this.$t('search.erro_pesquisa'))
        this.SET_LOAD()
      })
    }
  }
}
</script>
